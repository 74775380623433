<template>
  <div v-if="show">
    <v-row class="mx-1">
      <h2 class="custom-title-secondary my-1">Sentimos muito =(</h2>
      <span class="text-normal">
        Você não conseguirá prosseguir com o seu cadastro pois no momento
        atendemos exclusivamente:
      </span>
      <ul class="text-normal">
        <li class="font-weight-bold" v-if="!checkJuridicType">
          MEIs e Empresa do Simples Nacional;
        </li>
        <li class="font-weight-bold" v-if="!checkActivityNature">
          Prestadores de serviços.;
        </li>
        <li class="font-weight-bold" v-if="!checkHasEmployee">
          Empresas sem funcionários registrados (CLT);
        </li>
        <li class="font-weight-bold" v-if="!checkCity">
          Empresas cadastradas na cidade de São Paulo;
        </li>
        <li class="font-weight-bold" v-if="!isOpening && !checkCity">
          Caso queira trazer sua empresa para a cidade de São Paulo, clique
          "falar com nossa equipe";
        </li>
      </ul>
      <p class="custom-normal-text text-blue mt-3">
        Vamos guardar suas infomações e assim que tivermos novidades, entraremos
        em contato
      </p>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" sm="6" md="6" align-self="center">
        <v-btn class="custom-btn-green-inverse rounded-0" block @click="help()">
          Falar com nossa equipe
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-btn class="custom-btn-green rounded-0" block @click="restart()">
          Reiniciar processo
        </v-btn>
      </v-col>
    </v-row>
    <dialogs-contact
      :dialogShow="dialogShow"
      @close="dialogShow = $event"
    ></dialogs-contact>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    dialogsContact: () => import('./dialogs/dialogsContact.vue'),
  },
  data() {
    return {
      dialogShow: false,
      show: false,
    }
  },
  methods: {
    async restart() {
      await this.$store.dispatch('formDataUser/resetUserForm')
      this.$router.push({ name: 'quiz' })
      this.show = false
    },
    help() {
      this.dialogShow = true
    },
  },
  computed: {
    ...mapGetters({
      formCompany: 'formDataUser/formUser',
      isOpening: 'formDataUser/isOpening',
      checkCity: 'formDataUser/checkCity',
      checkActivityNature: 'formDataUser/checkActivityNature',
      checkHasEmployee: 'formDataUser/checkHasEmployee',
      checkJuridicType: 'formDataUser/checkJuridicType',
      checkCnae: 'formDataUser/checkCnae',
    }),
  },
  async mounted() {
    await this.$store.dispatch('formDataUser/getFormUser')
    this.show = true
  },
}
</script>
<style module lang="scss">
@import '@design';
</style>
